
import { defineComponent, reactive, ref, computed, onMounted } from "vue";
import PrivacyService from "../services/privacyPolicyService";
import TableControls from "./TableControls.vue";
import ContentModule from "../store/modules/Content/content";
import TableSettingsModule from "../store/modules/TableSettings/tableSettings";

const PrivacyPolicyDialog = () => import("../components/PrivacyPolicyDialog.vue");

const Multiselect = () => import("./Multiselect.vue");

export default defineComponent({
  name: "Footer",
  components: {
    PrivacyPolicyDialog,
    Multiselect,
    TableControls,
  },
  setup () {
    const isPolicyDialogVisible = reactive({ isVisible: false });
    const isPrivacyLoading = ref(false);
    const content = ref("");
    const footer = ref<null | { $el: HTMLElement }>(null);
    const contentCountForTaxonomy = computed(() => ContentModule.displayedContentCount);
    const fetchPrivacy = async () => {
      await PrivacyService.getExternalData().then(res => {
        content.value = res as string;
      });
    };
    const showDialog = async () => {
      isPrivacyLoading.value = true;
      isPolicyDialogVisible.isVisible = true;
      await fetchPrivacy();
      isPrivacyLoading.value = false;
    };
    const selectedMaterials = computed(() => {
      return ContentModule.getselectedMaterials;
    });
    const updateFooterHeight = () => {
      const windowHeight = window.innerHeight;
      let footerTop;
      if (footer.value) {
        footerTop = footer.value.$el.getBoundingClientRect().top;
      }
      TableSettingsModule.updateFooterHeight(windowHeight - footerTop);
    };

    const unloadEvents = () => {
      window.removeEventListener("resize", updateFooterHeight);
      window.removeEventListener("beforeunload", unloadEvents);
    };

    onMounted(() => {
      document.addEventListener("scroll", updateFooterHeight);
      window.addEventListener("resize", updateFooterHeight);
      window.addEventListener("beforeunload", unloadEvents);
      updateFooterHeight();
    });
    return { isPolicyDialogVisible, fetchPrivacy, showDialog, content, isPrivacyLoading, selectedMaterials, contentCountForTaxonomy, footer };
  },
});
