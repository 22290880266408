import axios from "axios";
import AuthModule from "../store/modules/Auth/auth";
import { useErrorPage } from "@/composables/errorPage";

const { correlationId } = useErrorPage();

// import createAuthRefreshInterceptor from "axios-auth-refresh";

/*
  *********for local development we need to have a .env.development.local file at the root of the project with one key**********

  client-app/.env.local
    VUE_APP_KRAMES_BASE_URL=http://local.fhir.kramesondemand.com/
*/
if (process.env.VUE_APP_KRAMES_BASE_URL) {
  axios.defaults.baseURL = process.env.VUE_APP_KRAMES_BASE_URL;
}

axios.interceptors.request.use(request => {
  if (AuthModule.getToken) {
    request.headers.Authorization = `Bearer ${AuthModule.getToken}`;
  }

  request.params = { ...request.params, correlationId: correlationId.value };
  return request;
});

try {
  axios.defaults.headers.common[`X-Utc-Offset`] = new Date().getTimezoneOffset();
} catch (e) { }

async function authenticate (params) {
  const axiosResponse = await axios
    .get(`api/authenticate?ehrName=${params.ehrName}&iss=${params.iss}&launch=${params.launch}&tenantId=${params.tenantId}&kofsid=${params.kofsid}`)
    .catch(e => console.log(e));
  if (!axiosResponse) {
    return { ehrName: null };
  } else {
    return axiosResponse.data;
  }
}

async function getToken (ehrName, params) {
  return await axios
    .get(`api/authorization/${ehrName}?state=${params.state}&code=${params.code}`)
    .then(r => r.data.BearerToken)
    .catch(e => console.log(e));
}

async function refreshState (params) {
  return await axios
    .get(`api/authorization/refresh/${params.state}`)
    .then(r => r.data.BearerToken)
    .catch(e => console.log(e));
}

async function refreshToken () {
  return await axios
    .get("api/refreshtoken/")
    .then(r => r.data)
    .catch(e => console.log(e));
}

async function expireToken () {
  return await axios
    .get("/api/expiretoken")
    .then(r => r.data)
    .catch(e => console.log(e));
}

// We are not using the interceptor to refresh the token
// createAuthRefreshInterceptor(axios, refreshToken);

export default { authenticate, getToken, expireToken, refreshState, refreshToken };
