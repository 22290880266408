import { Ref, ref } from "vue";
import router from "@/router/index";

import AuthModule from "@/store/modules/Auth/auth";

import auth from "@/services/authService";
import { useCerner } from "./setUpCerner";
import { useErrorPage } from "./errorPage";
import { useInitialAuthenticationStates } from "./initialAuthenticationStates";
const { setInitialStates } = useInitialAuthenticationStates();
const { loadCerner } = useCerner();
const { errorPage, showErrorPage } = useErrorPage();
const setEhrName = (ehrName: string) => {
  AuthModule.setEhrName(ehrName);
  if (ehrName === "Cerner") loadCerner();
};

const setInitialAuth = async (token: string, state: string): Promise<void> => {
  AuthModule.setToken(token);
  AuthModule.setStateId(state as string);
  AuthModule.startRefreshTimeout();
};

const validate = (queryParams) => {
  return (queryParams && queryParams.ehrName && queryParams.iss && queryParams.launch);
};

const completeAuthorization = async (ehrName: string, state: string, code: string, loading: Ref<boolean>, endpoint: string, tenantId: Ref<string>) => {
  const token = await auth.getToken(ehrName, { state, code });
  if (!token) {
    loading.value = false;
    errorPage.value = true;
    showErrorPage("The request could not be authenticated.");
  } else {
    setEhrName(ehrName);
    setInitialAuth(token, state as string);
    setInitialStates(state, endpoint, loading, tenantId);
  }
};

const authenticate = async (queryParams, loading: Ref<boolean>, endpoint: string, tenantId: Ref<string>) => {
  /*
    if there are no query params returned to the front end
    show the error page
  */
  if (!validate(queryParams)) {
    loading.value = false;
    showErrorPage("Application launch was unsuccessful: Invalid launch URL.");
  }

  const authParams = await auth.authenticate({ ehrName: queryParams.ehrName, iss: queryParams.iss, launch: queryParams.launch, tenantId: queryParams.tenantId, kofsid: queryParams.kofsid });
  if (!authParams.ehrName) {
    loading.value = false;
    errorPage.value = true;
    showErrorPage("The request could not be authenticated.");
  } else {
    if (authParams.pickerRedirectUrl != null) {
      // Redirect to the patient/provider picker
      window.location.href = authParams.pickerRedirectUrl;
    } else if (authParams.loginRedirectUrl != null) {
      // Redirect to the cerner login page
      window.location.href = authParams.loginRedirectUrl;
    } else {
      completeAuthorization(authParams.ehrName, authParams.state, authParams.code, loading, endpoint, tenantId);
    }
  }
};

const completeSamlAuthorization = (ehrName: string, state: string, token: string, endpoint: string, loading: Ref<boolean>, tenantId: Ref<string>) => {
  setEhrName(ehrName);
  setInitialAuth(token, state);
  setInitialStates(state, endpoint, loading, tenantId);
};

const refreshed = ref(false);
const refreshState = async (loading: Ref<boolean>, endpoint: string, tenantId: Ref<string>) => {
  loading.value = true;
  refreshed.value = true;
  const query = router.currentRoute.query;
  const state = query.state as string;
  await auth.refreshState({ state })
    .then(token => {
      setInitialAuth(token, state);
    });
  setInitialStates(state, endpoint, loading, tenantId);
};

const checkAuthentication = (ehrName, query, loading, tenantId, endpoint) => {
  // We check to see if the query.code is null, because if it's not, then we have a launch from the patient/provider picker redirect
  if (ehrName && ((ehrName.toLowerCase() === "epic" && query.code !== null) || ((ehrName.toLowerCase() === "smarthealthit" || ehrName.toLowerCase() === "cerner") && query.code == null))) {
    authenticate(query, loading, endpoint, tenantId);
  } else {
    if (ehrName && query.code && query.state) {
      // normal first load request, need to make a request to complete the authorization
      completeAuthorization(ehrName, query.state as string, query.code as string, loading, endpoint, tenantId);
    } else if (ehrName && query.state && query.token) {
      // SAML first load request, does not need to make a request, already has the token
      completeSamlAuthorization(ehrName, query.state as string, query.token as string, endpoint, loading, tenantId);
    } else if (query.state) {
      refreshState(loading, endpoint, tenantId);
    } else {
      loading.value = false;
      const errorMessage: string = query.errorMessage as string ?? "401 Authorization was not provided";
      showErrorPage(errorMessage);
    }
  }
};

const useAuthentication = () => {
  return { checkAuthentication, refreshed, authenticate, completeSamlAuthorization, refreshState };
};

export { useAuthentication };
