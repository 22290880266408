
import { defineComponent, computed, ref, reactive, watch, onMounted, WritableComputedRef, nextTick, onUnmounted } from "vue";
import { useVuetify } from "@/utility/useVuetify";
import SearchModule from "../store/modules/SearchBar/searchBar";
import typeaheadTerms from "../json/typeaheadTerms.json";
import UserModule from "@/store/modules/User/user";

export default defineComponent({
  name: "RecommendedHeader",
  props: {
    apisLoading: {
      type: Boolean,
      default: true,
    },
  },
  setup () {
    const vuetify = useVuetify();
    const loadingTerms = ref(false);
    const searching = computed(() => SearchModule.isSearching);
    const searchBar = ref();
    const windowHeight = ref(window.innerHeight);

    let autocompleteMenuListIndex = -1;
    const isFilterOpen: WritableComputedRef<boolean> = computed({
      get (): boolean {
        return SearchModule.isFilterOpen;
      },
      set (newValue: boolean): void {
        SearchModule.updateFilterStatus(newValue);
      },
    });
    const selected: WritableComputedRef<string|undefined> = computed({
      get (): string|undefined {
        return SearchModule.getSelectedSearchTerm;
      },
      set (newValue: string|undefined): void {
        SearchModule.updateSelectedSearchTerm(newValue);
      },
    });
    const typedTerms: WritableComputedRef<string[]> = computed({
      get (): string[] {
        return SearchModule.getTypedTerms;
      },
      set (newValue: string[]): void {
        SearchModule.updateTypedTerms(newValue);
      },
    });

    const areSearchResultsVisible = computed(() => SearchModule.searchResultsVisible);

    // add store and update value here
    const openFilter = async () => {
      try {
        isFilterOpen.value = true;
        await UserModule.updateFiltersOpen(true);
      } catch (e: any) {
        console.error("could not update userSettings", e);
      }
    };

    const searchTerm: WritableComputedRef<string> = computed({
      get (): string {
        return SearchModule.searchTerm;
      },
      set (newValue: string): void {
        SearchModule.updateSearchTerm(newValue);
      },
    });

    const searchTerms = computed(() => SearchModule.getSearchTerms);

    const filteredItems = computed(() => {
      const results = searchTerms.value.filter(item => {
        if (!searchTerm.value) {
          return "";
        }
        return item.toLowerCase().includes(searchTerm.value.toLowerCase());
      });

      results.unshift(...typedTerms.value);

      if (results.length >= 15) {
        return results.slice(0, 15);
      }

      return results;
    });

    const doSearch = async () => {
      await nextTick();
      if (!searchTerm.value) return;
      SearchModule.doSearch(true);
      searchBar.value.blur();
    };

    const addToTypedItems = (term: string) => {
      typedTerms.value = [];
      if (!term) return;

      typedTerms.value.push(term);
      selected.value = term;
    };

    const blurEvent = () => addToTypedItems(searchTerm.value);

    const changeEvent = () => doSearch();

    const searchEvent = () => {
      // We only want to handle the search here if no item is
      // highlighted in the autocomplete menu. The autocomplete
      // component already calls changeEvent() to handle a search
      // when a user highlights an item in the menu and hits their
      // enter key, so we don't want to hijack that event here.
      // See ticket TKOF-2778:
      // https://stay-well.atlassian.net/browse/TKOF-2778
      if (autocompleteMenuListIndex <= 0) {
        addToTypedItems(searchTerm.value);
        doSearch();
      }
    };

    const updateAutocompleteMenuListIndex = (listIndex: number) => {
      autocompleteMenuListIndex = listIndex;
    };

    const clearTypedTermsEvent = () => {
      typedTerms.value = [];
    };

    watch(() => searchTerm.value, (value) => {
      if (value) return;
      selected.value = undefined;
    });

    watch(() => UserModule?.getUserSettings?.userSettings?.FiltersOpen, async (persistedOpenFilter) => {
      const localStore = window.localStorage.getItem("openFilter");
      let shouldOpen = false;
      if (localStore) {
        try {
          shouldOpen = Number.parseInt(localStore) === 1;
          await UserModule.updateFiltersOpen(shouldOpen);
          window.localStorage.removeItem("openFilter");
        } catch (e: any) {
          console.error("could not update userSettings", e);
        }
      } else {
        shouldOpen = persistedOpenFilter;
      }
      if (shouldOpen && vuetify?.breakpoint.lgAndUp) {
        openFilter();
      }
    });

    const smallHeight = computed(() => {
      return windowHeight.value <= 700;
    });

    const setWindowHeight = () => {
      windowHeight.value = window.innerHeight;
    };

    onMounted(() => {
      // use imported search terms
      SearchModule.updateSearchTerms(typeaheadTerms.typeaheadTerms);
      // add event listener to resize search ahead results dropdown based on height of window
      window.addEventListener("resize", setWindowHeight);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", setWindowHeight);
    });
    return {
      areSearchResultsVisible,
      openFilter,
      isFilterOpen,
      searchTerm,
      searchTerms,
      typedTerms,
      filteredItems,
      selected,
      searchEvent,
      blurEvent,
      clearTypedTermsEvent,
      updateAutocompleteMenuListIndex,
      changeEvent,
      addToTypedItems,
      loadingTerms,
      searching,
      searchBar,
      smallHeight,
    };
  },
});
