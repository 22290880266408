/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ContentType } from "../../../models/contentType/contentType";
import store from "@/store/index";
Vue.use(Vuex);

@Module({ name: "ContentTypesModule", namespaced: true, dynamic: true, store })
class ContentTypesModule extends VuexModule {
  contentTypes = [] as ContentType[];

  get getContentTypes (): ContentType[] {
    return this.contentTypes;
  }

  get getContentTypesNames () {
    return this.contentTypes.map((type: ContentType) => type.ContentTypeName);
  }

  @Mutation
  SET_CONTENT_TYPES (contentTypes) {
    this.contentTypes = contentTypes;
  }

  @Action
  setContentTypes (contentTypes) {
    this.SET_CONTENT_TYPES(contentTypes);
  }

  get getContentTypeIdByContentTypeName () {
    return function (this: ContentTypesModule, contentTypeName: string): number | undefined {
      const contentType = this.contentTypes.find(contentType => contentType.ContentTypeName === contentTypeName);
      return contentType ? contentType.ContentTypeId : -1;
    };
  }

  get getContentTypeNameById () {
    return function (this: ContentTypesModule, contentId: string | number): string | undefined {
      const contentType = this.contentTypes.find(contentType => contentType.ContentTypeId === Number(contentId));
      return contentType ? contentType.ContentTypeName : "";
    };
  }
}

export default getModule(ContentTypesModule);
