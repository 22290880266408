/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import PrintService from "../../../services/printService";
import PublishedHistory from "../../../services/publishedHistoryService";
import CartModule from "../EducationCart/educationCart";
import ContentModule from "../Content/content";
import PatientModule from "../Patient/patient";
import PreviewModule from "../Preview/preview";
import printContent from "../../../utility/print.js";
import UserModule from "../User/user";
import UIConfigurationModule from "../../../store/modules/UIConfiguration/uiConfiguration";
import store from "@/store/index";
import { ContentList } from "@/models/content/content";
Vue.use(Vuex);

@Module({ name: "PrintShare", namespaced: true, dynamic: true, store })
class PrintShare extends VuexModule {
  content = "";
  materialsForPrintShare = [];
  singleMaterialForPrintShare = {} as ContentList;
  operating = {};
  multiPrint = false;
  isSnackbarVisible = false;
  successMessage = "";
  showEmailDialog = false;
  showPrePublishDialog = false;
  triggerEmailNotification = true;
  triggerPreviewReload = false;
  isPrint = true;
  isPrintOnly = false;
  distributionActions = {
    Printed: 0,
    Published: 1,
    PrintedAndPublished: 2,
    Previewed: 3,
    PrintedOnly: 4
  };

  get getContent () {
    return this.content;
  }

  get getOperating () {
    return this.operating;
  }

  get getSnackbarVisibility () {
    return this.isSnackbarVisible;
  }

  get getTriggerPreviewReload () {
    return this.triggerPreviewReload;
  }

  get getSuccessMessage () {
    return this.showEmailDialog ? "Published successfully" : this.isPrintOnly ? "Printed Successfully" : UserModule.displaySettings?.modals?.prePublishModal?.successMessage ?? "Published successfully";
  }

  get triggerEmailDialog () {
    let showDialog = !PatientModule.getEmail;
    showDialog = showDialog && UserModule.isPatientPortalEnabled;
    showDialog = showDialog && UIConfigurationModule.getUIConfiguration.ShowPortalPluginEmailModal;

    return showDialog;
  }

  get triggerPrePublishDialog () {
    const showDialog = this.isPrint
      ? UIConfigurationModule.getUIConfiguration.ShowPrintPublishModal
      : UIConfigurationModule.getUIConfiguration.ShowPublishModal;

    return showDialog;
  }

  get getShowEmailDialog () {
    return this.showEmailDialog;
  }

  get getShowPrePublishDialog () {
    return this.showPrePublishDialog;
  }

  @Mutation
  SET_PRINT_CONTENT (content: string) {
    this.content = content;
  }

  @Mutation
  SET_SHOW_EMAIL_DIALOG (flag: boolean) {
    this.showEmailDialog = flag;
  }

  @Mutation
  SET_SHOW_PREPUBLISH_DIALOG (flag: boolean) {
    this.showPrePublishDialog = flag;
  }

  @Mutation
  SAVE_STATE ({ isPrint, multiPrint, materials, triggerEmailNotification, isPrintOnly }) {
    if (typeof isPrint !== "undefined") this.isPrint = isPrint;
    if (typeof isPrintOnly !== "undefined")  this.isPrintOnly =  isPrintOnly;
    if (typeof triggerEmailNotification !== "undefined") this.triggerEmailNotification = triggerEmailNotification;

    if (typeof multiPrint !== "undefined") {
      this.multiPrint = multiPrint;
      multiPrint ? this.materialsForPrintShare = materials : this.singleMaterialForPrintShare = materials;
    }
  }

  @Mutation
  CLEAR_STATE () {
    this.materialsForPrintShare = [];
    this.singleMaterialForPrintShare = {} as ContentList;
  }

  @Mutation
  TOGGLE_SNACKBAR (flag: boolean) {
    this.isSnackbarVisible = flag;
  }

  @Mutation
  SET_TRIGGER_PREVIEW_RELOAD (flag: boolean) {
    this.triggerPreviewReload = flag;
  }

  @Action
  // eslint-disable-next-line
  async printOrShareMaterial ({ materials, isPrint, multiPrint, triggerEmailNotification, triggerPrePublishNotification, isPrintOnly }: any) {
    this.saveState({ materials, isPrint, multiPrint, triggerEmailNotification, isPrintOnly });

    const cartItems = this.multiPrint ? this.materialsForPrintShare : [this.singleMaterialForPrintShare];

    if (UserModule.displaySettings.cartItemLimit != null && UserModule.displaySettings.cartItemLimit <= 0) {
      UIConfigurationModule.setCartLimitExceededDialogVisibility(UserModule.displaySettings.cartItemLimit <= 0);
      return;
    }
    if (!cartItems || cartItems.length === 0) return Promise.resolve;

    this.SET_SHOW_EMAIL_DIALOG(this.triggerEmailDialog && this.triggerEmailNotification);

    if (this.triggerEmailDialog && this.triggerEmailNotification) return;

    this.SET_SHOW_PREPUBLISH_DIALOG(this.triggerPrePublishDialog && triggerPrePublishNotification !== false);

    if (this.triggerPrePublishDialog && triggerPrePublishNotification !== false) return;

    await this.publishItems({ cartItems });
  }

  @Action
  async publishItems ({ cartItems }) {
    let res;
    const cart = Object.assign({}, CartModule.getEducationCart);
    cart.CartItems = cartItems;
    cart.AddPatientName ? cart.Name = PatientModule.getPatient.DisplayName : cart.Name = "";
    cart.TimeZoneOffset = new Date().getTimezoneOffset();
    cart.PublishToPortal = true;
    cart.RemovePrintedItems = false;
    cart.PrintResultType = 1;
    cart.PrintCopies = 1;

    if (this.isPrintOnly){
      cart.AddToAvs = false;
      cart.DistributionAction = this.distributionActions.PrintedOnly;
      res = await PrintService.print(cart);
      this.SET_PRINT_CONTENT(res);
      printContent(res);
    }
    else if (this.isPrint) {
      cart.PatientEmailAddress = PatientModule.getEmail;
      cart.DistributionAction = this.distributionActions.PrintedAndPublished;
      res = await PrintService.print(cart);
      this.SET_PRINT_CONTENT(res);
      printContent(res);
    } else {
      cart.PatientEmailAddress = PatientModule.getEmail;
      cart.DistributionAction = this.distributionActions.Published;
      res = await PrintService.share(cart);
    }

    if (!res) return;

    if (UserModule.displaySettings.cartItemLimit != null) {
      UserModule.setUserSettingsCartItemLimit(UserModule.displaySettings.cartItemLimit - cart.CartItems.length);
    }

    if (this.multiPrint) {
      cartItems.forEach(content => {
        const taxonomyCode = content.Taxonomy?.Code ?? "";
        const contentId = content.ContentId;
        const contentTypeId = content.ContentTypeId;
        ContentModule.toggleEdited({ contentId, taxonomyCode, isEdited: false, contentTypeId });
      });
      ContentModule.updateSelectedMaterials([]);
    } else {
      this.SET_TRIGGER_PREVIEW_RELOAD(true);
      if(!PreviewModule.isPreviewOpen) {
        const taxonomyCode = cartItems[0]?.Taxonomy?.Code ?? "";
        const contentTypeId = cartItems[0]?.ContentTypeId;
        const contentId = cart.CartItems[0]?.ContentId;
        ContentModule.toggleEdited({ contentId, taxonomyCode, isEdited: false, contentTypeId });
      }
    }

    const newPublishedHistory = await PublishedHistory.getPublishedHistory();
    ContentModule.setPublishedMaterials(newPublishedHistory);

    this.toggleSnackbar(true);
    if (this.multiPrint || this.singleMaterialForPrintShare.ContentId === cart.CartItems[0].ContentId) {
      this.CLEAR_STATE();
    }
  }

  @Action
  // eslint-disable-next-line
  saveState ({ isPrint, multiPrint, materials, triggerEmailNotification, isPrintOnly }: any) {
    this.SAVE_STATE({ isPrint, multiPrint, materials, triggerEmailNotification, isPrintOnly });
  }

  @Action
  async toggleSnackbar (flag: boolean) {
    this.TOGGLE_SNACKBAR(flag);
  }

  @Action
  async setShowPrePublishDialog (flag: boolean) {
    this.SET_SHOW_PREPUBLISH_DIALOG(flag);
  }

  @Action
  togglePreviewReload (flag: boolean) {
    this.SET_TRIGGER_PREVIEW_RELOAD(flag);
  }

}

export default getModule(PrintShare);
