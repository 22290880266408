/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import AuthService from "../../../services/authService";
import store from "@/store/index";
Vue.use(Vuex);

@Module({ name: "Auth", namespaced: true, dynamic: true, store })
class Auth extends VuexModule {
  ehrName = "";
  token = "";
  stateId = "";
  tokenRefreshTimeout;
  isTokenExpired = false;
  isDialogVisible = false;

  get getEhrName () {
    return this.ehrName;
  }

  get getTokenStatus () {
    return this.isTokenExpired;
  }

  get getToken () {
    return this.token;
  }

  get getStateid () {
    return this.stateId;
  }

  get getTimerId () {
    return this.tokenRefreshTimeout;
  }

  get getIsDialogVisible () {
    return this.isDialogVisible;
  }

  @Mutation
  SET_IS_DIALOG_VISIBLE (isVisible: boolean) {
    this.isDialogVisible = isVisible;
  }

  @Mutation
  SET_EHR_NAME (ehrName: string) {
    this.ehrName = ehrName;
  }

  @Mutation
  SET_TOKEN (token: string) {
    this.token = token;
  }

  @Mutation
  SET_STATEID (stateId: string) {
    this.stateId = stateId;
  }

  @Mutation
  SET_TOKEN_REFRESH_TIMEOUT (tokenRefreshTimeout: number) {
    this.tokenRefreshTimeout = tokenRefreshTimeout;
  }

  @Mutation
  SET_IS_TOKEN_EXIPRED (isExpired: boolean) {
    this.isTokenExpired = isExpired;
  }

  @Action
  setIsDialogVisible (isVisible: boolean) {
    this.SET_IS_DIALOG_VISIBLE(isVisible);
  }

  @Action
  setEhrName (ehrName: string) {
    this.SET_EHR_NAME(ehrName);
  }

  @Action
  setToken (token: string) {
    this.SET_TOKEN(token);
  }

  @Action
  setStateId (stateId: string) {
    this.SET_STATEID(stateId);
  }

  @Action
  async startRefreshTimeout () {
    const tokenObj = await this.parseTokenToObject(this.token);
    const millis = await this.calculateExpireTimeout(tokenObj);

    if (this.tokenRefreshTimeout != null) clearTimeout(this.tokenRefreshTimeout);

    if (!millis) {
      console.error("Token refresh couldn't be set. Token not found.");
      return;
    }

    this.SET_TOKEN_REFRESH_TIMEOUT(setTimeout(() => this.refreshToken(), millis));
  }

  @Action
  async refreshToken () {
    const token = await AuthService.refreshToken();
    this.SET_TOKEN(token.accessToken);
    this.startRefreshTimeout();
  }

  @Action
  parseTokenToObject (accessToken: string) {
    if (!accessToken) return null;
    const base64Url = accessToken.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const tokenObj = JSON.parse(window.atob(base64));

    return tokenObj;
  }

  @Action
  async calculateExpireTimeout (tokenObj): Promise<number|null> {
    if (!tokenObj) return null;
    const expireTimeout = new Date(tokenObj.exp * 1000);
    const issuedAt = new Date(tokenObj.iat * 1000);
    const minutesDiff = (expireTimeout.getTime() - issuedAt.getTime()) / 60000;
    const millisecondsDiff = minutesDiff * 60 * 1000;
    const millisecondsBeforeExpires = minutesDiff > 2 ? 120000 : 5;

    return millisecondsDiff - millisecondsBeforeExpires;
  }

  @Action
  async expireToken () {
    if (this.getTimerId) {
      clearTimeout(this.getTimerId);
    }
    this.SET_IS_TOKEN_EXIPRED(true);
    await AuthService.expireToken();
  }
}

export default getModule(Auth);
